
/* Component Dependencies */
var cardTextTemplate = require('templates/cardText.hbs');
var cardTextWithHeadingTemplate = require('templates/cardText-headingWithSubtitle.hbs');
var cardTextWithHeadingMainTemplate = require('templates/cardText-heading.hbs');
var cardTextDrawerSectionHeadline = require('templates/cardText-drawerSectionHeadline.hbs');
var cardTextDescriptionWithLinkTemplate = require('templates/cardText-descriptionWithLink.hbs')
var cardTextDestination = require('templates/cardText-destination.hbs');
var cardTextOnlyLink = require('templates/cardText-onlyLink.hbs');
var cardTextBanner = require('templates/cardText-banner.hbs');
var AriesComponent = require('libs/aries-component');
var cardTextMissingOutBannerTemplate = require('templates/cardText-missingOutBanner.hbs');
var Popup = require('libs/popup');
var Cookies = require('libs/cookies');
AriesComponent.create({

  type: 'cardText',

  template: {
    'cardText': cardTextTemplate,
    'cardText-headingWithSubtitle': cardTextWithHeadingTemplate,
    'cardText-heading': cardTextWithHeadingMainTemplate,
    'cardText-drawerSectionHeadline': cardTextDrawerSectionHeadline,
    'cardText-descriptionWithLink': cardTextDescriptionWithLinkTemplate,
    'cardText-destination': cardTextDestination,
    'cardText-onlyLink': cardTextOnlyLink,
    'cardText-banner': cardTextBanner,
    'cardText-missingOutBanner': cardTextMissingOutBannerTemplate
  },
  init: function() {
   
  },
  events: {
    'click .js-m-translation-btn': 'closeTranslationPopup'
  },
  unBindEvents: function($) {
    $('*[data-component-id="' + this.$el.data('component-id') + '"]').off();
    $('*[data-component-id="' + this.$el.data('component-id') + '"] *').off();
  },

  bindEvents: function() {
    var _self = this;
    var $cardText = $('.cardText');
    var $ctBnner = _self.$el.hasClass('tile-card-text-banner');
    var $cancellationFeeBanner = _self.$el.hasClass('tile-card-text-banner-cancellation-fee');
    var banner, cookieToSet;

    if ($ctBnner) {
      banner = 'ctBanner';
      cookieToSet = 'ctBannerCounter'
    } else if ($cancellationFeeBanner) {
      banner = 'cancellationFeeBanner';
      cookieToSet = 'cancellationFeeBannerCounter'
    }

    if ($cardText.length > 0) {
      var popUpFlag = $cardText.data('popup');
      if(popUpFlag ) {
        var transferPointsPopup = new Popup({
          selector: '.tile-card-text .mi-popover',
          sourceBlock: '.modal-content',
          alternateCloseMarkup: true
        });
        transferPointsPopup.register();
      }
    }
    if (_self.$el.find('.js-learn-more-link').length){
      var efolioPopup = new Popup({
        reverseCloseIcon: false,
        selector: '.tile-card-text-description-with-link .mi-popover',
        mainClass: 'm-modal t-modal-med',
        sourceBlock: '.modal-content'
      });
      efolioPopup.register();
    }

    //Adding custom click tracking for sign-in and join-now
    var customClickTrack = $('.tile-card-text .custom_click_track');
    if(customClickTrack.length) {
      var joinNowBtn = $('.cardText a[href*="/loyalty/createAccount/createAccountPage1.mi"]'),
      signInBtn = $('.cardText a[href*="/sign-in.mi"]');
      joinNowBtn.attr("custom_click_track_value", "Marriott Bonvoy Card Text|Join Now|internal|event167," + "evar48=MER-web-joinnow-cardtext" );
      signInBtn.attr("custom_click_track_value", "Marriott Bonvoy Card Text|Sign In|internal|event167," + "evar48=MER-web-signin-cardtext" );
    }
    if ($ctBnner || $cancellationFeeBanner) {
      this.registerBannerEvents(banner, cookieToSet);
    }

  },

  registerBannerEvents: function _registerBannerEvents (bannerName, cookieName) {
    var _self = this, sessionCookie;
    if (bannerName === 'ctBanner') {
      sessionCookie = 'ctLastSessionID';
    } else if (bannerName === 'cancellationFeeBanner') {
      sessionCookie = 'cancellationFeeSessionID';
    }
    
    var currentSessionId = _self.getSessionToken(), 
        pageViewed = Cookies.readCookie(cookieName),
        lastSessionId = Cookies.readCookie(sessionCookie),
        pageBannerLimit = parseInt(_self.$el.data('bannerLimit')),
        pageViewedCount = pageViewed ? parseInt(pageViewed) : 0;
    if (bannerName === 'ctBanner') {
      if(!window.cardTextBannerExecuted) {
        if (currentSessionId === lastSessionId && pageViewedCount <= pageBannerLimit){
          pageViewedCount++;
        }
        else if (currentSessionId !== lastSessionId){
          pageViewedCount = 1;
          lastSessionId = currentSessionId;
        }
      }
      window.cardTextBannerExecuted = true;
    }  else if (bannerName === 'cancellationFeeBanner') {
      if(!window.cardTextFeeBannerExecuted) {
        if (currentSessionId === lastSessionId && pageViewedCount <= pageBannerLimit){
          pageViewedCount++;
        }
        else if (currentSessionId !== lastSessionId){
          pageViewedCount = 1;
          lastSessionId = currentSessionId;
        }
      }
      window.cardTextFeeBannerExecuted = true;
    }
    
    Cookies.createCookie(cookieName, pageViewedCount);
    Cookies.createCookie(sessionCookie, lastSessionId);
    if (bannerName === 'ctBanner') {
      _self.$el.find('.js-banner-close').on('click', function(){
        _self.$el.addClass('l-display-none').attr('aria-hidden',true);
        pageViewedCount = pageBannerLimit + 1;
        Cookies.createCookie(cookieName, pageViewedCount);
      });
    } else if (bannerName === 'cancellationFeeBanner') {
      _self.$el.find('.js-fee-banner-close').on('click', function(){
        _self.$el.addClass('l-display-none').attr('aria-hidden',true);
        pageViewedCount = pageBannerLimit + 1;
        Cookies.createCookie(cookieName, pageViewedCount);
      });
    }
    if (_self.isShowBanner(pageBannerLimit, cookieName)){
      _self.$el.removeClass('l-display-none').attr('aria-hidden',false);
      $('#page-container.fixed-header').removeClass('fixed-header');
      $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 1) {
          _self.$el.addClass('l-display-none');
          $('#page-container').addClass('fixed-header');
        } 
        else {
          if (_self.isShowBanner(pageBannerLimit, cookieName)){
            _self.$el.removeClass('l-display-none');
            $('#page-container.fixed-header').removeClass('fixed-header');
          }
        }
      });
    } else if (bannerName != 'cancellationFeeBanner' || bannerName != 'ctBanner'){
      _self.$el.addClass('l-display-none').attr('aria-hidden',true);
    }
  },


  isShowBanner: function _isShowBanner(pageBannerLimit, cookieToRead) {
    if (parseInt(Cookies.readCookie(cookieToRead)) <= pageBannerLimit) {
      return true;
    }
    else
      return false;
  },
  closeTranslationPopup:function closePopUp(){
    $('.m-modal-close').trigger("click");
  }
});